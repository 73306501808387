var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"border-radius-xl mt-6"},[_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-data-table',{staticClass:"table border-radius-xl",attrs:{"headers":_vm.headers,"items":_vm.orders,"search":_vm.search,"page":_vm.page,"hide-default-footer":"","items-per-page":_vm.itemsPerPage,"mobile-breakpoint":"0"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"border-radius-xl",attrs:{"flat":"","height":"80"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{staticClass:"\n                    input-style\n                    font-size-input\n                    text-light-input\n                    placeholder-light\n                    input-icon\n                  ",attrs:{"hide-details":"","dense":"","flat":"","filled":"","solo":"","height":"43","placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('template',{slot:"prepend-inner"},[_c('v-icon',{staticClass:"material-icons-round mt-n2",attrs:{"color":"#adb5bd","size":"18px"}},[_vm._v("search")])],1)],2)],1)],1)],1)]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-checkbox',{attrs:{"ripple":false,"color":"#141727"},model:{value:(item.checkbox),callback:function ($$v) {_vm.$set(item, "checkbox", $$v)},expression:"item.checkbox"}}),_c('span',{staticClass:"my-2 text-xs text-body font-weight-light"},[_vm._v(_vm._s(item.id))])],1)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"my-2 text-xs text-body font-weight-light"},[_vm._v(_vm._s(item.date))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"me-2",class:item.statusIcon == 'fas fa-check'
                  ? 'border-success'
                  : 'border-default',attrs:{"ripple":false,"icon":"","outlined":"","rounded":"","width":"25px","height":"25px"}},[_c('v-icon',{class:item.statusIcon == 'fas fa-check'
                    ? 'text-success'
                    : 'text-dark',attrs:{"size":"8"}},[_vm._v(" "+_vm._s(item.statusIcon)+" ")])],1),_c('span',{staticClass:"text-body text-xs"},[_vm._v(_vm._s(item.status))])],1)]}},{key:"item.customer",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.customerImg)?_c('v-avatar',{staticClass:"me-2",attrs:{"size":"24"}},[_c('v-img',{attrs:{"src":item.customerImg}})],1):_c('v-avatar',{staticClass:"me-2 bg-gradient-default",attrs:{"size":"24"}},[_c('span',{staticClass:"text-white text-xs"},[_vm._v(_vm._s(item.customer[0]))])]),_c('span',{staticClass:"text-body text-xs font-weight-light"},[_vm._v(_vm._s(item.customer))])],1)]}},{key:"item.product",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"my-2 text-xs text-body font-weight-light"},[_vm._v(_vm._s(item.product))])]}},{key:"item.revenue",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"my-2 text-xs text-body font-weight-light"},[_vm._v(_vm._s(item.revenue))])]}}])})],1),_c('v-card-actions',{staticClass:"card-padding"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"6","lg":"3"}},[_c('span',{staticClass:"text-body me-3 text-sm"},[_vm._v("Items per page:")]),_c('v-text-field',{staticClass:"\n              font-size-input\n              placeholder-lighter\n              text-color-light\n              input-alternative input-focused-alternative input-icon\n            ",attrs:{"hide-details":"","type":"number","outlined":"","min":"-1","max":"15","background-color":"rgba(255,255,255,.9)","color":"rgba(0,0,0,.6)","light":"","value":_vm.itemsPerPage,"placeholder":"Items per page"},on:{"input":function($event){_vm.itemsPerPage = parseInt($event, 10)}}})],1),_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"#d81b60","length":_vm.pageCount,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }